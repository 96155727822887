import { SportsDic } from "models/sportsDic";

/**
 * NOTE 設定は仮置き
 */
export const lacrosse: SportsDic = {
  playerHasUniformNumber: true,
  hasUniformNumber: true,
  hasPosition: true,
  isFixedPlayerPositionNum: false,
  teamImgIsCircle: false,
  startingMemberNum: 10,
  reserveMemberNum: 13,
  isPeriodFinish: true,
  isDisplaySeconds: false,
  isHiddenReserveMember: false,
  positionList: {
    ja: [
      {
        id: "G",
        name: "G"
      },
      {
        id: "DF",
        name: "DF"
      },
      {
        id: "MF",
        name: "MF"
      },
      {
        id: "FO",
        name: "FO"
      },
      {
        id: "LSM",
        name: "LSM"
      },
      {
        id: "AT",
        name: "AT"
      },
      {
        id: "GM",
        name: "GM"
      },
      {
        id: "H",
        name: "監督"
      },
      {
        id: "C",
        name: "コーチ"
      },
      {
        id: "S",
        name: "スタッフ"
      }
    ],
    en: [
      {
        id: "G",
        name: "G"
      },
      {
        id: "DF",
        name: "D"
      },
      {
        id: "MF",
        name: "M"
      },
      {
        id: "FO",
        name: "FOGO"
      },
      {
        id: "LSM",
        name: "LSM"
      },
      {
        id: "AT",
        name: "A"
      },
      {
        id: "GM",
        name: "GM"
      },
      {
        id: "H",
        name: "Head Coach"
      },
      {
        id: "C",
        name: "Coach"
      },
      {
        id: "S",
        name: "Support Staff"
      }
    ]
  },
  statTypes: [
    {
      key: "Goal",
      name: "ゴール",
      name_en: "Goal",
      name_short: "ゴール",
      name_short_en: "Goal",
      point: 1,
      gamePoint: 1,
      iconText: "G",
      isAssist: true
    }
  ],
  periodNames: {},
  infringementNames: []
};
