export type Sports = {
  id?: string;
  name: string;
  name_en?: string;
};

export const SportsEnum = {
  basketball3x3: "basketball3x3",
  darts: "darts",
  rugby: "rugby",
  skijump: "skijump",
  soccer: "soccer",
  tennis: "tennis",
  volleyball: "volleyball",
  flyingdisc: "flyingdisc",
  baseball: "baseball",
  americanfootball: "americanfootball",
  hockey: "hockey",
  basketball: "basketball",
  badminton: "badminton",
  handball: "handball",
  lacrosse: "lacrosse"
};
