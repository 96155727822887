import rugbyBg from "../images/sports/rugby/bg.png";
import soccerBg from "../images/sports/soccer/bg.png";
import tennisBg from "../images/sports/tennis/bg.png";
import basketball3x3Bg from "../images/sports/basketball3x3/bg.png";
import basketballBg from "../images/sports/basketball/bg.jpg";
import volleyballBg from "../images/sports/volleyball/bg.png";
import flyingdiscBg from "../images/sports/flyingdisc/bg.jpg";
import baseballBg from "../images/sports/baseball/bg.jpg";
import americanfootball from "../images/sports/americanfootball/bg.jpg";
import hockeyBg from "../images/sports/hockey/bg.png";
import motorsportsBg from "../images/sports/motorsports/bg.jpg";

import rugbyBall from "../images/sports/rugby/ball.png";
import soccerBall from "../images/sports/soccer/ball.png";
import tennisBall from "../images/sports/tennis/ball.png";
import basketball3x3Ball from "../images/sports/basketball3x3/ball.png";
import basketballBall from "../images/sports/basketball/ball.png";
import volleyballBall from "../images/sports/volleyball/ball.png";
import flyingdiscBall from "../images/sports/flyingdisc/ball.png";
import baseballBall from "../images/sports/baseball/ball.png";
import americanfootballBall from "../images/sports/americanfootball/ball.png";
import hockeyBall from "../images/sports/hockey/ball.png";

import rugbyBallBlackBorder from "../images/sports/rugby/ball_black_border.png";
import soccerBallBlackBorder from "../images/sports/soccer/ball_black_border.png";
import tennisBallBlackBorder from "../images/sports/tennis/ball_black_border.png";
import basketball3x3BallBlackBorder from "../images/sports/basketball3x3/ball_black_border.png";
import basketballBallBlackBorder from "../images/sports/basketball/ball_black_border.png";
import volleyballBallBlackBorder from "../images/sports/volleyball/ball_black_border.png";
import flyingdiscBallBlackBorder from "../images/sports/flyingdisc/ball_black_border.png";
import baseballBallBlackBorder from "../images/sports/baseball/ball_black_border.png";
import americanfootballBallBlackBorder from "../images/sports/americanfootball/ball_black_border.png";
import hockeyBallBlackBorder from "../images/sports/hockey/ball_black_border.png";

import motorSportsHelmet from "assets/images/sports/motorsports/default_team_icon.png";
import badmintonBg from "../images/sports/badminton/bg.png";
import badmintonBall from "../images/sports/badminton/ball.png";
import signageHandball from "../images/sports/handball/signage_bg.jpg";
import handballBall from "../images/sports/handball/ball.png";
import handballBallBlackBorder from "../images/sports/handball/ball_black_border.png";

// TODO 背景がないので一旦コメントアウト
//import signageLacrosse from "../images/sports/lacrosse/signage_bg.jpg";
import lacrosseBall from "../images/sports/lacrosse/ball.png";
import lacrosseBallBlackBorder from "../images/sports/lacrosse/ball_black_border.png";
interface Images {
  [key: string]: string;
}

export const splashBg = {
  rugby: rugbyBg,
  soccer: soccerBg,
  tennis: tennisBg,
  basketball3x3: basketball3x3Bg,
  volleyball: volleyballBg,
  flyingdisc: flyingdiscBg,
  baseball: baseballBg,
  americanfootball: americanfootball,
  hockey: hockeyBg,
  motorsports: motorsportsBg,
  basketball: basketballBg,
  badminton: badmintonBg,
  group: rugbyBg,
  handball: signageHandball,
  lacrosse: signageHandball
} as Images;

export const sportsIconGreen = {
  rugby: rugbyBall,
  soccer: soccerBall,
  tennis: tennisBall,
  basketball3x3: basketball3x3Ball,
  volleyball: volleyballBall,
  flyingdisc: flyingdiscBall,
  baseball: baseballBall,
  americanfootball: americanfootballBall,
  hockey: hockeyBall,
  motorsports: motorSportsHelmet,
  basketball: basketballBall,
  badminton: badmintonBall,
  handball: handballBall,
  lacrosse: lacrosseBall
} as Images;

export const SportsIconBlackBorder = {
  rugby: rugbyBallBlackBorder,
  soccer: soccerBallBlackBorder,
  tennis: tennisBallBlackBorder,
  basketball3x3: basketball3x3BallBlackBorder,
  volleyball: volleyballBallBlackBorder,
  flyingdisc: flyingdiscBallBlackBorder,
  baseball: baseballBallBlackBorder,
  americanfootball: americanfootballBallBlackBorder,
  hockey: hockeyBallBlackBorder,
  motorsports: motorSportsHelmet,
  basketball: basketballBallBlackBorder,
  badminton: badmintonBall,
  handball: handballBallBlackBorder,
  lacrosse: lacrosseBallBlackBorder
} as Images;
