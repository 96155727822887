import americanfootball from "assets/images/sports/americanfootball/ball.png";
import baseball from "assets/images/sports/baseball/ball.png";
import basketball3x3 from "assets/images/sports/basketball3x3/ball.png";
import basketball from "assets/images/sports/basketball/ball.png";
import flyingdisc from "assets/images/sports/flyingdisc/ball.png";
import rugby from "assets/images/sports/rugby/ball.png";
import soccer from "assets/images/sports/soccer/ball.png";
import motorsports from "assets/images/sports/motorsports/helmet.png";
import badminton from "assets/images/sports/badminton/ball.png";
import handball from "assets/images/sports/handball/ball.png";
import lacrosse from "assets/images/sports/lacrosse/ball.png";

export const defaultSportsImage: { [key: string]: string } = {
  americanfootball,
  baseball,
  basketball3x3,
  flyingdisc,
  rugby,
  soccer,
  motorsports,
  basketball,
  badminton,
  handball,
  lacrosse
};
